import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function FallFestival() {
  useDocumentTitle("Fall Festival 2024");
  ReactGA.send({ hitType: "pageview", page: "/fall-festival-2024" });

  return (
    <>
      <section
        className="text-center pt-0 hero-home special-banner bg-center bg-100-height"
        style={{
          background: "url(img/fall-festival-2024-bg.jpg)",
          backgroundColor: "#b4336a",
        }}
      ></section>

      <section className="pb-5" id="fall-festival-content">
        <div className="container py-5">
          <header className="mb-4 mb-5 text-center">
            <h2 className="text-uppercase">Family Fall Festival!</h2>
            <p className="text-serif mb-0 text-primary">
              Join us for a day of fun and fellowship
            </p>
          </header>
          <div className="row align-items-stretch gx-0">
            <div className="col">
              <h4>Welcome!</h4>
              <p>
                Love of Christ CSI Church is excited to invite you to our annual Family Fall
                Festival! This event is a wonderful opportunity for our
                community to come together and enjoy the fall season in a fun,
                family-friendly environment.
              </p>

              <h4>Event Details</h4>
              <div className="highlight">
                <p>
                  <strong>Date:</strong> November 23th, 2023 <br />
                  <strong>Time:</strong> 11:00 AM - 4:00 PM <br />
                  <strong>Location:</strong> Love of Christ CSI Church - 12717 Marsh Ln, Farmers Branch, TX 75234
                </p>
              </div>

              <h4>Activities</h4>
              <ul>
                <li>Rides</li>
                <li>Caricature</li>
                <li>Photo Ops</li>
                <li>Games & Prizes</li>
                <li>Face Painting</li>
                <li>Bounce Houses</li>
                <li>Delicious Food & Drinks</li>
                <li>And much more!</li>
              </ul>

              <h4>What to Bring</h4>
              <p>
                Bring your family, friends, excitement and huge appetite and
                enjoy the festivities!
              </p>
              <p><a class="btn btn-primary btn-lg" href="https://docs.google.com/forms/d/e/1FAIpQLSfa8a51Lo5lc33Q-UWRRQaWuNkYqSU7eSfMY3-ZvfmQ-b3AZQ/viewform?usp=sf_link" target="_blank" rel="noreferrer">RSVP to the event</a></p>

              <h4>Contact</h4>
              <p>
                For any questions, please contact us at +1 214-699-9525 or{' '}
                <a href="mailto: FallFestival@loccsi.org">FallFestival@loccsi.org</a>.
              </p>

              <h4>We can't wait to see you there!</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
