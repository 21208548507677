import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../../../components/useDocumentTitle";

export default function Kids() {
  useDocumentTitle("Children's Ministry");
  ReactGA.send({ hitType: "pageview", page: "/kids" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/kids-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Children's Ministry</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Children’s Ministry</h2>
          </header>
          <div className="row gy-4">
            <div className="col-xs-12">
              <figure class="figure float-md-start imgshadow me-md-4 mb-4">
                <img
                  src="img/kids-1.jpg"
                  class="figure-img img-fluid"
                  alt="Kids"
                  style={{ maxWidth: 450, width: "100%" }}
                ></img>
                <figcaption class="figure-caption text-center">
                  Sunday Class for the Intermediate Kids @ Love of Christ CSI
                  Church
                </figcaption>
              </figure>
              <blockquote className="blockquote">
                <p className="mb-2">
                  Train up a child in the way he should go, And even when he is
                  old he will not depart from it - Proverbs 22:6e
                </p>
              </blockquote>
              <p>
                Children are a heritage from the Lord. Ministering the spiritual
                needs and well-being of the youth and children of a church is
                one of the most important ministries of a church. Shaping the
                leaders of the future is a privilege and ought be done with
                utmost reverence. Love of Christ CSI Church is committed to
                serve the children and youth of the church and guide them
                towards the purpose that God has planned for them.
              </p>
              <p>
                Sunday classes are held in the church campus every Sunday at
                11:00 AM. The team has teachers who passionately serve the age
                group of 3-16. Age-appropriate lessons, aligning with CSI
                doctrines are taught to guide them in their journey with Christ.
              </p>
              <h4>Sunday School Curriculum:</h4>
              <ul>
                <li>
                  Ages 3-5 learn a series of topics with engaging crafts and
                  action songs.
                </li>
                <li>
                  Ages 6-7 learn a curriculum that gives them a series of Bible
                  lessons and activities.
                </li>
                <li>
                  Ages 8-10 learn a curriculum that gives them a series of Bible
                  lessons along with follow-up activities such as daily
                  devotions that can also be used as family devotions in the
                  family prayer.
                </li>
                <li>
                  Ages 11-15 learn a curriculum approved by the CSI Diaspora
                  Diocese. This includes concepts of canonization of the Bible,
                  history of Bible, Trinity, Creeds, Sacraments, Commandments,
                  Sin, Grace, Salvation etc. This curriculum helps the children
                  to grow rooted in the teachings of Christ, introspect
                  themselves and defend their faith in Jesus Christ.
                </li>
              </ul>
              <figure class="figure float-md-end imgshadow ms-md-4 mb-4">
                <img
                  src="img/kids-2.jpg"
                  class="figure-img img-fluid"
                  alt="Kids"
                  style={{ maxWidth: 450, width: "100%" }}
                ></img>
                <figcaption class="figure-caption text-center">
                  Sunday school for the toddlers @ Love of Christ CSI Church
                </figcaption>
              </figure>
              <h4>Few Important Events:</h4>
              <p>
                The Children's Ministry actively organizes several programs for
                our children. Some highlights are as follows.
              </p>
              <ul>
                <li>
                  Guided by the National Sunday School Committee of the CSI
                  Diaspora Diocese, our children along with the other CSI
                  children of USA participate in the lenten devotions with daily
                  devotions and weekly gatherings through Zoom conducted by the
                  Sunday school leaders of the CSI churches all over the
                  country.
                </li>
                <li>
                  The Children’s Ministry plays Bible-based movies to the
                  children encouraging them to grow spiritually and morally in
                  Christ. Watching Bible-based movies can have many benefits,
                  including reinforcing teachings, promoting positive values,
                  providing a positive entertainment option, starting
                  conversations about faith, learning about God, seeing the
                  Bible's truth brought to life, considering the Bible's
                  perspective etc.
                </li>
                <li>
                  The Children’s Ministry organizes several outreach
                  volunteering events in places like “Feed My starving
                  Children”, a Christian non-profit committed to feed the
                  undernourished children worldwide. Volunteers (children and
                  parents) participate in the event and pack meals for the
                  underprivileged children. Children witness the impact of
                  living out their faith, developing empathy and finding joy in
                  giving and serving.
                </li>
                <li>
                  Worship service is led by the Sunday school children and
                  teachers and is celebrated as Children’s Sunday. We praise God
                  for the opportunity to encourage children to exhibit
                  leadership in the Worship service and use their talents in
                  music and singing for the glory of God.
                </li>
                <li>
                  Love of Christ CSI Church hosts Vacation Bible School (VBS)
                  every year which can be a milestone in a child's faith
                  development. It can help children to develop spiritual
                  friendships, experience God's love, find mentorship
                  relationships, create memories that support a healthy faith
                  journey. Kids, teachers and volunteers participate in this
                  event. The purpose of VBS is to minister children in the
                  church, create outreach to the surrounding communities, and to
                  create evangelism opportunities. This purpose is supported by
                  a creative theme of Bible study, activities, worship, and even
                  time for some tasty snacks.
                </li>
              </ul>
              <h4>Join Us!</h4>
              <p>
                We invite you to come, learn, participate and volunteer in the
                Children’s Ministry at Love of Christ CSI Church. We would love
                to see you there. May God bless you all!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
