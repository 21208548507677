import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../../../components/useDocumentTitle";

export default function BibleStudy() {
  useDocumentTitle("Bible Study");
  ReactGA.send({ hitType: "pageview", page: "/bible-study" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/bible-study-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Bible Study</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
            Deepen Your Faith
            </p>
            <h2 className="text-uppercase">Through Fellowship and Study</h2>
          </header>
          <div className="row gy-4">
            <div className="col">
              <section>
                <p>
                  Our Adult Bible Study provides a welcoming space for adults of
                  all ages and backgrounds to come together, delve into the Word
                  of God, and engage in meaningful conversations about
                  Scripture. We aim to foster spiritual growth and cultivate a
                  deeper understanding of our faith.
                </p>
              </section>

              <section>
                <h4>What to Expect</h4>
                <ul>
                  <li>
                    <b>Regular Meetings:</b> We meet every Sunday from 11:00 AM
                    to 12:00 PM, except when there's a conflicting church
                    program.{" "}
                  </li>
                  <li>
                    <b>Open and Welcoming Atmosphere:</b> Feel comfortable
                    asking questions and sharing your thoughts in a
                    judgment-free environment.
                  </li>
                  <li>
                    <b>Expert Guidance:</b> Our pastors offer valuable insights
                    and guidance, enriching our understanding of the scriptures.
                  </li>
                  <li>
                    <b>Diverse Perspectives:</b> Benefit from the diverse
                    perspectives and experiences of fellow participants,
                    fostering a rich learning environment.
                  </li>
                  <li>
                    <b>Lay Leadership:</b> Occasionally, lay members lead the
                    study, strengthening their faith and providing fresh
                    perspectives to the group.
                  </li>
                </ul>
              </section>

              <section>
                <p>
                  Our discussions focus on the practical application of biblical
                  teachings, helping you:
                </p>
                <div class="highlight">
                  <ul>
                    <li>Gain valuable life lessons.</li>
                    <li>Make wise decisions guided by faith.</li>
                    <li>
                      Positively impact your family, workplace, and community.
                    </li>
                  </ul>
                </div>
              </section>

              <section>
                <h4>Join Us!</h4>
                <p>
                  We invite you to join our Adult Bible Study and embark on a
                  journey of faith and discovery. Come and experience the joy of
                  learning and growing together in Christ.
                </p>
                <p>
                  For more information, please contact our Presbyter-in-charge.
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
