import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../../../components/useDocumentTitle";

export default function PrayerCare() {
  useDocumentTitle("Prayer & Care Ministry");
  ReactGA.send({ hitType: "pageview", page: "/prayer-care" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/prayer-care-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Prayer & Care Ministry</h1>
                  {/* <p className="lead">
                    Prayer and Care Ministry.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">Supporting You</p>
            <h2 className="text-uppercase">Through Prayer and Care</h2>
          </header>
          <div className="row gy-4">
            <div className="col">
              <blockquote className="blockquote">
                <p className="mb-2">
                  Let us then with confidence draw near to the throne of grace,
                  that we may receive mercy and find grace to help in time of
                  need - Hebrews 4:16
                </p>
              </blockquote>

              <p>
                At Love of Christ CSI Church, our Prayer & Care Ministry is
                dedicated to providing spiritual and emotional support to our
                congregation. We believe in the power of prayer and the
                importance of community in times of need. Our primary objective
                is to:
              </p>
              <ul>
                <li>
                  Offer consistent prayer support and encouragement for those
                  facing personal, emotional, or spiritual challenges.
                </li>
                <li>
                  Foster a sense of community by encouraging members to support
                  one another through prayer and acts of kindness.
                </li>
              </ul>

              <h4>ACTS Prayer Fellowship</h4>
              <p>
                Our ACTS (Adoration, Confession, Thanksgiving, and Supplication)
                Prayer Fellowship meets every Thursday at 8:30 PM through Zoom.
                This fellowship provides a dedicated space for:
              </p>
              <ul>
                <li>
                  Interceding for the needs of our church members and community.
                </li>
                <li>Sharing prayer requests and testimonies.</li>
                <li>Engaging in personal reflection and confession.</li>
                <li>
                  Reading Scripture and praying for various needs, including
                  global issues, peace, unity, and guidance for leaders.
                </li>
              </ul>
              <p>
                The ACTS Prayer Fellowship is a beautiful reminder of God's
                faithfulness and the power of collective prayer.
              </p>

              <h4>Chain Prayers</h4>
              <p>
                In times of specific need, we initiate chain prayers, lifting up
                those who require healing, health, protection, and providence.
                We have witnessed countless testimonies of God's presence and
                answers to prayer through this initiative. As Psalm 50:15 says,
                "Call upon Me in the day of trouble; I will deliver you, and you
                shall glorify Me." We continually call upon the Lord
                collectively to uphold those going through struggles.
              </p>

              <h4>Caring for Our Community</h4>
              <p>
                Our Prayer & Care team also provides practical support to our
                members, including:
              </p>
              <div class="highlight">
                <ul>
                  <li>Visiting the sick and grieving families.</li>
                  <li>Offering emotional support and encouragement.</li>
                  <li>
                    Providing meals and connecting members to necessary
                    resources in times of crisis.
                  </li>
                  <li>
                    Acknowledging and celebrating significant life events such
                    as births, weddings, and anniversaries.
                  </li>
                </ul>
              </div>
              <p>
                Through these initiatives, we strive to ensure that all members
                of our church community feel supported, nurtured, and loved.
              </p>

              <section>
                <h4>Get Involved!</h4>
                <p>
                  If you would like to join our Prayer & Care Ministry or have a
                  prayer request, please contact our Presbyter-in-charge.
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
