import React from "react";
import ReactGA from "react-ga4";
import "./offering.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function Offering() {
  useDocumentTitle('Offering');

  const collectOffering = () => {
    ReactGA.event({
      category: "Engagement",
      action: "Online Offering Clicked",
    });
  }

  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/offering-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Offerings</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5" id="donation">
        <div className="container">
          <div className="row text-center gy-4">
            <div className="col-xs-12">
              <p>
                Welcome to the Love of Christ CSI Church Donation Page – a space
                where your generosity becomes a catalyst for positive change and
                impactful ministry. Your financial support enables us to
                continue spreading the love of Christ, nurturing spiritual
                growth, and serving our community with compassion.
              </p>
            </div>
          </div>
          <header className="mb-4 text-center mb-5 mt-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Why Give?</h2>
          </header>

          <div className="row text-center gy-4">
            <div className="col-xs-12">
              <p>
                Your donations play a crucial role in sustaining the various
                ministries, outreach programs, and day-to-day operations of Love
                of Christ CSI Church. By contributing, you become an integral
                part of our mission to love God, love people, and make a lasting
                impact in the lives of those around us.
              </p>
            </div>
          </div>
          <div className="row align-items-stretch">
            <div className="h-100 d-flex flex-column p-5 justify-content-center">
              <header className="mb-4 text-center mb-5 mt-5">
                <p className="text-serif text-primary mb-0">Your Donation</p>
                <h2 className="text-uppercase">How does it help?</h2>
              </header>
              <ul className="list-check ps-0">
                <li>
                  <p>
                    <strong>Worship Experiences</strong>: Enhance the quality of
                    worship services and create an atmosphere where individuals
                    can connect with God.
                  </p>
                </li>

                <li>
                  <p>
                    <strong>Community Outreach</strong>: Support our outreach
                    efforts to meet the needs of those less fortunate, both
                    locally and globally.
                  </p>
                </li>

                <li>
                  <p>
                    <strong>Ministry Development</strong>: Nourish the growth of
                    our ministries, ensuring we provide meaningful experiences
                    for all age groups.
                  </p>
                </li>

                <li>
                  <p>
                    <strong>Facility Maintenance</strong>: Maintain a welcoming
                    and safe environment for fellowship, worship, and community
                    events.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 bg-light py-lg-5">
              <div className="h-100 d-flex flex-column p-5 justify-content-center">
                <header className="mb-4 text-center mb-5">
                  <p className="text-serif text-primary mb-0">
                    Available options for you to contribute <sup>*</sup>
                  </p>
                <h2 className="text-uppercase">Ways to Give</h2>
                </header>
                <p className="text-muted">
                  Every donation, regardless of size, makes a significant impact
                  on the lives touched by Love of Christ CSI Church. Your
                  support fuels our mission, allowing us to be a beacon of hope,
                  compassion, and love in our community.
                </p>
                <ul className="list-check ps-0">
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      <strong>Write a Cheque</strong> in favor of "Love of
                      Christ CSI" and send it to our address.
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      <strong>Zelle</strong> to the email address,
                      treasurer@loccsi.org
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      <strong>Online Payment</strong> by clicking the link
                      <a
                        className="btn btn-primary btn-sm ms-1"
                        style={{ display: "inline" }}
                        href="https://loveofchristcsi.churchcenter.com/giving?open-in-church-center-modal=true"
                        onClick={() => collectOffering()}
                      >
                        Donate online
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
