import React from "react";
import ReactGA from "react-ga4";
import "./ministries.scss";
import Card from 'react-bootstrap/Card';
import useDocumentTitle from "../../components/useDocumentTitle";
import { CardLink } from "react-bootstrap";
import { map } from "lodash";

export default function Ministries() {
  useDocumentTitle("Ministries");
  ReactGA.send({ hitType: "pageview", page: "/ministries" });

  const allMinistries = [
    {
      title: 'Children\'s Ministry',
      image: 'img/kids-bg.jpg',
      description: 'Love of Christ CSI Church is committed to serve the children and youth of the church and guide them towards God’s purpose for them. We use age-appropriate curriculum from the CSI Diaspora Diocese, specifically designed for children of CSI heritage growing in the USA.',
      path: '/kids'
    },
    {
      title: 'Bible Study',
      image: 'img/bible-study-bg.jpg',
      description: 'Adults of all ages come together every Sunday between 11:00 AM-12:00 PM to deep dive into the word of God in a group and to learn from one another.',
      path: '/bible-study'
    },
    {
      title: 'Music Ministry',
      image: 'img/music-bg.jpg',
      description: 'At Love of Christ CSI Church, Music ministry plays a vital role in leading the congregation in singing and edify the worship experience. We encourage all members of the congregation who are interested in Music or Singing to join the music ministry and use their talents for the glory of God.',
      path: '/music'
    },
    {
      title: 'Outreach Ministry',
      image: 'img/outreach-bg.jpg',
      description: 'Outreach Ministry at our church focuses on reaching out the community locally and globally, to be a witness for Christ and to share God’s love through words and deeds and make positive impact in the lives of people, reflecting the vision of the church.',
      path: '/outreach'
    },
    {
      title: 'Prayer and Care Ministry',
      image: 'img/prayer-care-bg.jpg',
      description: 'To foster a loving, compassionate and caring church community, Love of Christ CSI Church focuses on uplifting people through prayer and care. The ACTS Prayer fellowship meets every Thursday at 8:30 PM through zoom to pray for the needs of the church and for each other.',
      path: '/prayer-care'
    },
    {
      title: 'Cottage prayer meeting',
      image: 'img/cottage-bg.jpg',
      description: 'Every month, Love of Christ CSI Church gathers at the host`s home to foster a more intimate setting for prayer, fellowship, sharing testimonies, discussing spiritual insights and more.',
      path: '/cottage-prayer'
    },
    {
      title: 'Tech Arts Ministry',
      image: 'img/tech-arts-bg.jpg',
      description: 'The Tech Arts Ministry uses technology and creative arts to enhance the worship experience and communicate the message of the Gospel. Our team handles sound, video production, graphic design, and other technical and artistic contributions to the church\'s ministry.',
      path: '/tech-arts'
    }
  ]

  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/ministries-bg.jpg)", backgroundPosition: "center"}}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Ministries</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="about-us">
        <div className="container">
          <div className="row gy-4">
            <div className="col-xs-12">
              <div className="row gy-4">
                <div className="col-xs-12">
                  <p>At Love of Christ CSI Church, we believe in the power of connection and the importance of growing in faith together. Our diverse ministries offer a place for everyone to find belonging, deepen their relationship with God, and use their gifts to serve others. Whether you're seeking spiritual growth, community outreach, or opportunities to express your talents, we invite you to explore the many ways to get involved.</p>
                  <p>Our ministries are designed to nurture your spirit, strengthen your faith, and equip you to live a life that reflects God's love. Come join us as we learn, grow, and serve together!</p>
                  <h3>Our various ministries</h3>
                </div>
                {map(allMinistries, (ministry) => (<div key={ministry.title} className="col-md-6 d-flex">
                  <Card>
                    <Card.Img 
                      variant="top" 
                      src="img/transparent.png" 
                      style={{
                        maxHeight: 200, 
                        background: `url(${ministry.image})`, 
                        backgroundPosition: "center", 
                        backgroundSize: 'cover'
                      }} 
                    />
                    <Card.Body>
                      <Card.Title>{ministry.title}</Card.Title>
                      <Card.Text>
                        {ministry.description}
                      </Card.Text>
                      <CardLink href={ministry.path}>Learn More</CardLink>
                    </Card.Body>
                  </Card>
                </div>))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
