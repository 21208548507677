import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../../../components/useDocumentTitle";

export default function Outreach() {
  useDocumentTitle("Outreach Ministry");
  ReactGA.send({ hitType: "pageview", page: "/outreach" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/outreach-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Outreach Ministry</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Outreach Ministry</h2>
          </header>
          <div className="row gy-4">
            <div className="col-12">
              <p>
                Our Outreach Ministry at Love of Christ CSI Church is dedicated
                to sharing the love of Christ both locally and globally. We
                strive to:
              </p>
              <ul>
                <li>
                  <b>Spread the Gospel:</b> We plan and organize evangelism
                  programs in our local community, encouraging the congregation
                  to actively participate in praying and supporting our efforts
                  to share the good news.
                </li>
                <li>
                  <b>Serve those in need:</b> We develop projects and programs
                  to make a positive impact in the lives of individuals and
                  communities around us, both locally and globally, by serving
                  and providing for their needs.
                </li>
              </ul>
              <p>
                We are grateful for the opportunity to serve and share God's
                love. Here are some of the examples of our accomplishments:
              </p>
              <figure class="figure float-md-start imgshadow me-md-4 mb-4">
                <img
                  src="img/outreach-1.jpg"
                  class="figure-img img-fluid"
                  alt="Outreact"
                  style={{ maxWidth: 450, width: "100%" }}
                ></img>
                <figcaption class="figure-caption text-center">
                  Love of Christ CSI Church @ Anand Bazaar
                </figcaption>
              </figure>
              <div class="highlight">
                <ul>
                  <li>
                    <b>Evening at Senior Living:</b> Members of Love of Christ
                    CSI Church spent time with seniors, praying, singing and
                    sharing God's message with them.
                  </li>
                  <li>
                    <b>Flood Relief Efforts:</b> The men of Love of Christ CSI
                    Church organized a fundraiser and raised $2100 to support
                    three CSI churches in the Thoothukudi and Tirunelveli
                    districts of Tamil Nadu that were severely affected by
                    flood.
                  </li>
                  <li>
                    <b>Feed My Starving Children:</b> We volunteered to pack
                    food packets for the underprivileged kids across the world
                    through Feed My Starving Children (A christian non-profit).
                  </li>
                  <li>
                    <b>Anand Bazaar Outreach:</b> We set up an outreach booth at
                    the 47th Anand Bazaar event on August 31st, 2024, at Frisco
                    RoughRiders Stadium. Over 20 members from our congregation
                    participated, sharing the love of Christ with over 300
                    people through gospel tracts, New Testament Bibles, engaging
                    conversations, and children's activities. We were thrilled
                    to have about 10 visitors express interest in learning more
                    about our church.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12">
              <h4>Join Us!</h4>
              <p>
                We are always looking for individuals who are passionate about
                sharing God's love and serving others. If you would like to get
                involved in our Outreach Ministry, we invite you to join us!
                There are many ways to participate, including:
              </p>
              <ul>
                <li>
                  <b>Prayer:</b> Pray for our outreach efforts and those we
                  serve.
                </li>
                <li>
                  <b>Volunteering:</b> Volunteer your time and talents to help
                  with our various outreach programs.
                </li>
              </ul>
              <p>
                To learn more about how you can get involved, please contact our
                Presbyter-in-charge.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-full">
              <h5>Take a quick glimpse of our outreach activities!</h5>
            </div>
            <div className="col">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/LhBNwlpKvAE?si=UM-kd4qE4PbikyWo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/aFLufnXSxxs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
