import React, { useEffect, useState } from "react";
import Loader from "../../components/loader";
import useDocumentTitle from "../../components/useDocumentTitle";
import Sermons from "./components/sermons";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { map } from "lodash";

export default function SermonsPage() {
  useDocumentTitle("All Sermons");
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/videoPageData`;

  const [videos, setVideos] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    fetch(dataUrl)
      .then((response) => response.json())
      .then((videoData) => {
        setVideos(videoData);
        setLoading(false);
      });
  }, [dataUrl]);

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(
          map(videos, (video) => ({
            "@context": "https://schema.org/",
            "@type": "VideoObject",
            name: video.title,
            description: video.description,
            contentUrl: video.url,
            embedUrl: `https://www.youtube.com/embed/${video.id}`,
            license: "https://loveofchristcsichurch.org/license",
            uploadDate: video.actualStartTime,
            thumbnailUrl: video.thumbnail,
            acquireLicensePage:
              "https://loveofchristcsichurch.org/how-to-use-my-images",
            creditText: "Love of Christ CSI",
            creator: {
              "@type": "Organization",
              name: "Love of Christ CSI Church",
              url: "https://loveofchristcsichurch.org",
            },
            copyrightNotice: "Love of Christ CSI",
          }))
        )}
      </script>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/pastors-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Sermons</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5 text-center">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Watch our services online
            </p>
            <h2 className="text-uppercase">Our sermons</h2>
          </header>
          <div className="row text-center gy-4">
            <div className="col-xs-12">
              {isLoading && (
                <div className="loader-photo">
                  <Loader></Loader>
                </div>
              )}
              {!isLoading && (
                <Sermons
                  videos={videos}
                  isLoading={isLoading}
                  onClick={(video) => {
                    setSelectedVideo(video);
                    return false;
                  }}
                ></Sermons>
              )}
            </div>
          </div>
        </div>
      </section>
      <ModalVideo
        chanel="youtube"
        autoplay
        loop="1"
        isOpen={!!selectedVideo}
        videoId={selectedVideo?.id}
        onClose={() => setSelectedVideo(undefined)}
      />
    </>
  );
}

<iframe
  width="1038"
  height="584"
  src="https://www.youtube.com/embed/MVlz1-EwT00"
  title="Sunday Service October 6, 2024"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerpolicy="strict-origin-when-cross-origin"
  allowfullscreen
></iframe>;
