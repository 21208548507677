import { useEffect, useState } from "react";
import Events from "./components/events";
import HomeHero from "./components/hero";
import Sermons from "./components/sermons";

import "./home.scss";
import { useLocation } from "react-router-dom";
import useDocumentTitle from "../../components/useDocumentTitle";
import News from "./components/news";
import LiveStreaming from "./components/live";
import HomeSpecialHero from "./components/special";

export default function Home() {
  useDocumentTitle("Home");
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/homePageData`;

  const { hash } = useLocation();

  const [data, setData] = useState({
    videos: [],
    events: [],
    news: [],
    liveStreams: {},
  });
  const [isLoading, setLoading] = useState(false);

  const isSpecialHero = (process.env.REACT_APP_ENABLE_SPECIAL_BANNER || 'false') === 'true';

  useEffect(() => {
    if (hash) {
      setTimeout(
        () =>
          document.querySelector(hash)?.scrollIntoView({ behavior: "smooth" }),
        300
      );
    }
  }, [hash]);

  useEffect(() => {
    setLoading(true);
    fetch(dataUrl)
      .then((response) => response.json())
      .then((homePageData) => {
        setData(homePageData);
        setLoading(false);
      });
  }, [dataUrl]);

  return (
    <>
      {isSpecialHero && (<HomeSpecialHero></HomeSpecialHero>)}
      {!isSpecialHero && <HomeHero></HomeHero>}
      {data.liveStreams && data.liveStreams.video && (
        <LiveStreaming
          video={data.liveStreams.video}
          isUpcoming={data.liveStreams.isUpcoming}
          isLoading={isLoading}
        ></LiveStreaming>
      )}
      <section className="py-5" id="services">
        <div className="container">
          <header className="mb-4 text-center">
            <p className="text-serif mb-0 text-primary">Welcome to</p>
            <h2 className="text-uppercase">Love of Christ CSI Church</h2>
          </header>
          <div className="row text-center gy-4">
            <div className="col-xs-12">
              <p>
                Love of Christ CSI Church, a vibrant community under The CSI
                Diaspora Diocese, stands as a beacon of spiritual growth and
                communal connection in the heart of the Dallas Metroplex. As one
                of the newest churches in our diocese, we are dedicated to
                passionately spreading the transformative message of our Lord
                Jesus Christ. Here in the thriving cultural mosaic of Dallas, we
                embrace diversity, foster fellowship, and carry the light of
                Christ into every corner of our community. Join us on this
                inspiring journey of faith, love, and service as we grow
                together in His grace.
              </p>
            </div>
          </div>
        </div>
      </section>
      <News newsItems={data.news} isLoading={isLoading}></News>
      <section className="" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Watch our services online
            </p>
            <h2 className="text-uppercase">Our latest sermons</h2>
          </header>
          <Sermons videos={data.videos} isLoading={isLoading}></Sermons>
        </div>
      </section>
      <Events events={data.events} isLoading={isLoading}></Events>

      <section className="pb-5">
        <div className="container pb-5">
          <div className="row align-items-center text-center gy-2">
            <a
              className="col-lg-3 col-sm-6"
              href="https://www.anglicancommunion.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/anglican_communion.png" alt="..." height="100" />
              <br />
              The Anglican Communion
            </a>
            <a
              className="col-lg-3 col-sm-6"
              href="https://www.csi1947.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/csi.png" alt="..." height="100" />
              <br />
              Church of South India
            </a>
            <a
              className="col-lg-3 col-sm-6"
              href="https://www.csidiasporadiocese.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/csi-diaspora.png" alt="..." height="100" />
              <br />
              CSI Diaspora Diocese
            </a>
            <a
              className="col-lg-3 col-sm-6"
              href="https://loveofchristcsichurch.org/"
            >
              <img src="img/loccsi.svg" alt="..." height="100" />
              <br />
              Love of Christ CSI Church
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
