import { map } from "lodash";
import Loader from "../../../components/loader";
import moment from "moment";
import { Link } from "react-router-dom";

export default function News({ newsItems = [], isLoading = false }) {
  return (
    <>
      <section className="bg-light py-5" id="news">
        <div className="container">
        <header className="mb-4 text-center mb-5">
          <p className="text-serif mb-0 text-primary">
            Whats happening
          </p>
          <h2 className="text-uppercase">Latest News</h2>
        </header>
          <div className="row align-items-center">
            {isLoading && <div className="col-auto mx-auto"><Loader></Loader></div>}
            {!isLoading && map(newsItems, (newsItem, index) => (
              <div key={`${newsItem.datePublished}-${index}`} className="col-md-6 mb-4 mb-lg-0">
                <div className="row gy-4">
                  <div className="col-lg-4 text-center">
                    <div className="d-flex flex-column align-items-center justify-content-center justify-content-lg-start">
                      <p className="text-gray text-l h1 mb-0">
                        {moment(newsItem.datePublished).format("DD")}
                      </p>
                      <div className="ms-2">
                        <h5 className="mb-0">
                          {moment(newsItem.datePublished).format("MMMM")}
                        </h5>
                        <p className="text-small mb-0 text-muted">
                          {moment(newsItem.datePublished).format("dddd")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 text-left">
                    <p className="text-serif mb-4 text-muted overflow-trim-3">
                      {newsItem.headline}
                    </p>
                    <small>{newsItem.author}</small>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-md-12 mt-4 mb-4 mb-lg-0 text-end">
                <Link className="btn btn-secondary btn-sm" to="/news">View All News</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
