import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../../../components/useDocumentTitle";

export default function TechArts() {
  useDocumentTitle("Tech Arts Ministry");
  ReactGA.send({ hitType: "pageview", page: "/tech-arts" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/tech-arts-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Tech Arts Ministry</h1>
                  {/* <p className="lead">
                    Tech arts ministry.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-2" id="intro">
        <div className="container pt-5">
          <div className="row gy-4">
            <div className="col">
              <p>
                The Tech Arts Ministry uses technology and creative arts to
                enhance the worship experience and communicate the message of
                the Gospel. Our team handles sound production, video production,
                graphic design, and other technical and artistic contributions
                to the church's ministry. We strive to create a dynamic and
                engaging worship experience, utilize technology and creativity
                to reach a wider audience, and provide opportunities for
                individuals to use their technical and artistic skills to serve
                God.
              </p>
              <p>
                Tech Arts Ministry is multifaceted, and we would like to split
                our reports into 2 key areas:
                <ol>
                  <li>Worship experience</li>
                  <li>Publicity</li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-2" id="worship-experience">
        <div className="container py-2">
          <header className="mb-4 text-center mb-5">
            <h2 className="text-uppercase">Worship experience</h2>
            <p className="text-serif mb-0 text-primary">
              at Love of Christ CSI Church
            </p>
          </header>
          <div className="row gy-4">
            <div className="col">
              <figure class="figure float-md-start imgshadow me-md-4 mb-4">
                <img
                  src="img/tech-arts-2.jpg"
                  class="figure-img img-fluid"
                  alt="Tech Arts Work"
                  style={{ maxWidth: 450, width: "100%" }}
                ></img>
                <figcaption class="figure-caption text-center">
                  Tech Arts team at work during Sunday service
                </figcaption>
              </figure>
              <p>
                We, at Love of Christ CSI Church, strive to create a worship
                experience that is both engaging and uplifting, drawing people
                closer to God through a harmonious blend of audio, visual, and
                online elements.
              </p>
              <h4>Audio Excellence</h4>
              <p>
                Our dedicated audio team works tirelessly to ensure that every
                note and word resonates clearly and powerfully, whether you're
                worshipping with us in the sanctuary or online. We've invested
                in high-quality microphones and speakers, strategically placed
                and meticulously tuned to deliver a rich and immersive
                soundscape. And to mark the start of our worship service in a
                truly special way, the traditional church bell now rings out
                electronically, signaling a time for reflection and connection.
              </p>
              <p>
                We continually assess our equipment, fine-tuning and optimizing
                its performance. As technology advances and our needs evolve,
                we'll work closely with the church committee to explore new
                equipment and hardware, ensuring that your worship experience
                remains sonically exceptional.
              </p>
              <h4>Visual Impact</h4>
              <p>
                We've ushered in a new era of visual clarity with the
                installation of a state-of-the-art laser projector. Say goodbye
                to dim, blurry images and hello to vibrant, crystal-clear
                presentations that enhance every aspect of our worship service.
              </p>
              <p>
                To seamlessly orchestrate our visual elements, we utilize
                Worship Tools, a powerful platform that allows us to:
                <ul>
                  <li>
                    Craft engaging presentations: Effortlessly create beautiful
                    slides for the order of worship, ensuring a smooth and
                    inspiring flow.
                  </li>
                  <li>
                    Maintain an up-to-date song library: Keep our song lyrics
                    current and easily accessible, allowing everyone to sing
                    along with confidence.
                  </li>
                  <li>
                    Share announcements and sermon presentations: Utilize Google
                    Slides to deliver impactful announcements and visually
                    enriching presentations during the sermon.
                  </li>
                  <li>
                    Incorporate captivating videos: Enhance the worship
                    experience with inspiring and thought-provoking videos.
                  </li>
                </ul>
                We're also exploring alternative tools that can streamline our
                behind-the-scenes activities, making our visual production even
                more efficient and effective.
              </p>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col">
              <h4>Live Streaming Connection</h4>
              <p>
                We at Love of Christ CSI Church ensure that everyone gets a
                chance to feel the uplifting power of worship irrespective of
                their location. We have invested in a live streaming setup that
                brings you our services right to your screens.
              </p>
              <p>
                Currently, the setup includes two high-definition cameras, a
                strong streaming computer, and an easy-to-use streaming
                platform. With this, we can capture the dynamic energy of the
                music to the insightful message of the sermon in each of our
                services.
              </p>
              <p>
                We continue to seek ways to improve our live stream quality and
                reach so that the online worship experience may be engaging and
                accessible for all to enjoy. Join us online each week and feel
                the warmth of our community right from the comfort of your
                homes.
              </p>
              <h4>Join Our Team!</h4>
              <p>
                We're always looking for passionate individuals to join our Tech
                Arts Team. If you're interested in learning the ropes and
                contributing your skills, we'd love to have you! Please reach
                out to the Tech Arts Team to learn more.
              </p>
              <h4>Our Ongoing Commitment</h4>
              <p>
                We continually pray that our audio, visual, and broadcast
                production provides the best possible experience, whether you're
                worshipping with us in the sanctuary or online. We are dedicated
                to creating an environment where everyone can connect with God
                and experience the transformative power of worship.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-2 pb-5" id="worship-experience">
        <div className="container pb-2">
          <header className="mb-4 text-center mb-5">
            <h2 className="text-uppercase">Publicity</h2>
            <p className="text-serif mb-0 text-primary">
              at Love of Christ CSI Church
            </p>
          </header>
          <div className="row gy-4">
            <div className="col">
              <figure class="figure float-md-end imgshadow ms-md-4 mb-4">
                <img
                  src="img/publicity-1.jpg"
                  class="figure-img img-fluid"
                  alt="Publicity"
                  style={{ maxWidth: 450, width: "100%" }}
                ></img>
                <figcaption class="figure-caption text-center">
                  Women of Love of Christ CSI Church taking a photo
                </figcaption>
              </figure>
              <p>
                Behind the scenes at Love of Christ CSI Church is a dedicated
                team of artistic individuals working tirelessly to get the word
                out about our very vibrant community and the lifesaving message
                that we share. The Publicity Team drives our engaging online
                presence, creating catchy ads, compelling social media posts,
                and captivating promotions on YouTube, Facebook, and Instagram.
              </p>
              <p>
                They are the storytellers of our church and strive to create the
                essence of our worship experiences, ministries, and community
                outreach in a catchy way that sparks words. Their passion for
                sharing God's love shines through on each and every post, reel,
                video, and announcement by inviting others to become part of our
                ever-growing family.
              </p>
              <h4>Want to connect with us?</h4>
              <p>
                Find us on YouTube:{" "}
                <a
                  href="https://www.youtube.com/@loveofchristcsi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  youtube.com/@loveofchristcsi
                </a>{" "}
                - Subscribe to our channel for inspiring sermons, uplifting
                music performances, and glimpses into our church life.
              </p>
              <p>
                Like us on Facebook:{" "}
                <a
                  href="https://www.facebook.com/loveofchristcsi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  facebook.com/loveofchristcsi
                </a>{" "}
                - Stay up-to-date on the latest news, events, and announcements.
              </p>
              <p>
                Follow us on Instagram:{" "}
                <a
                  href="https://www.instagram.com/loveofchristcsi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  instagram.com/loveofchristcsi
                </a>{" "}
                - Get a behind-the-scenes look at our church community and
                connect with us on a personal level.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
