import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function HomeHero() {
  return (
    <>
      <section className="text-center pt-lg-0 hero-home" id="hero">
        <Swiper
          loop={true}
          spaceBetween={0}
          effect={"fade"}
          navigation={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          modules={[Autoplay, EffectFade, Navigation, Pagination]}
        >
          <SwiperSlide
            className="hero-slide bg-cover py-3 with-border-image d-flex align-items-center"
            style={{
              background: "url(img/hero-bg-2.jpg)",
              backgroundPositionX: "center",
            }}
          >
            <div className="container text-white py-5 my-5 index-forward">
              <h1 className="text-uppercase text-xl mt-5">Pray with us</h1>
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <p className="lead">
                    For where two or three gather in my name, there am I with
                    them.
                    <br />
                    <small>Matthew 18:20</small>
                  </p>
                  {/* <a className="btn btn-primary px-4" href="text.html">Read More</a> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="hero-slide bg-cover py-3 with-border-image d-flex align-items-center"
            style={{
              background: "url(img/hero-bg-3.jpg)",
              backgroundPositionX: "center",
            }}
          >
            <div className="container text-white py-5 my-5 index-forward">
              <h1 className="text-uppercase text-xl mt-5">
                Elevate Your Worship
              </h1>
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <p className="lead">
                    Oh come, let us worship and bow down; let us kneel before
                    the Lord, our Maker!
                    <br />
                    <small>Psalm 95:6</small>
                  </p>
                  {/* <a className="btn btn-primary px-4" href="text.html">Read More</a> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="hero-slide bg-cover py-3 with-border-image d-flex align-items-center"
            style={{
              background: "url(img/hero-bg-1.jpg)",
              backgroundPositionX: "right",
            }}
          >
            <div className="container text-white py-5 my-5 index-forward">
              <h1 className="text-uppercase text-xl mt-5">
                Experience The Love of Christ
              </h1>
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <p className="lead">
                    Love one another. As I have loved you, so you must love one
                    another.
                    <br />
                    <small>John 13:34</small>
                  </p>
                  {/* <a className="btn btn-primary px-4" href="text.html">Read More</a> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section id="features">
        <div className="container-fluid p-0">
          <div className="row p-0 text-center align-items-stretch g-0">
            <div className="col-lg-3 col-md-6">
              <div className="px-5 py-3 text-white h-100 bg-primary">
                <h3 className="h4">Love</h3>
                <p className="mb-0 text-small">
                  Let us love one another, because love is from God; everyone who loves is born of God and knows God.<br/><span className="bible-reference">1 John 4:7</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="px-5 py-3 text-white h-100 bg-primary-shade">
                <h3 className="h4">Fellowship</h3>
                <p className="mb-0 text-small">
                  For where two or three are gathered in my name, I am there among them.<br/><span className="bible-reference">Matthew 18:20</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 order-lg-3">
              <div className="px-5 py-3 text-white h-100 bg-primary">
                <h3 className="h4">Communion</h3>
                <p className="mb-0 text-small">
                  For as often as you eat this bread and drink the cup, you proclaim the Lord's death until he comes.<br/><span className="bible-reference">1 Corinthians 11:26</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 order-lg-4">
              <div className="px-5 py-3 text-white h-100 bg-primary-shade">
                <h3 className="h4">Church Beliefs</h3>
                <p className="mb-0 text-small">
                  So we, who are many, are one body in Christ, and individually we are members one of another.<br/><span className="bible-reference">Romans 12:5</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
