import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../../../components/useDocumentTitle";

export default function CottagePrayerMeeting() {
  useDocumentTitle("Cottage Prayer Meeting");
  ReactGA.send({ hitType: "pageview", page: "/cottage-prayer" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/cottage-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Cottage Prayer Meeting</h1>
                  {/* <p className="lead">
                    Cottage Prayer Meeting
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Intimate Gatherings for
            </p>
            <h2 className="text-uppercase">Prayer and Fellowship</h2>
          </header>
          <div className="row gy-4">
            <div className="col">
              <figure class="figure float-md-start imgshadow me-md-5 mb-4">
                <img
                  src="img/cottage-1.jpg"
                  class="figure-img img-fluid"
                  alt="Cottage Prayer"
                  style={{ maxWidth: 450, width: "100%" }}
                ></img>
                <figcaption class="figure-caption text-center">
                  Prayer Time during our Cottage Prayer Meeting
                </figcaption>
              </figure>
              <p>
                Our Cottage Prayer Ministry offers a unique opportunity to
                connect with God and fellow believers in a warm, intimate
                setting. Once a month, we gather at the homes of our church
                members to:
              </p>
              <ul>
                <li>
                  <b>Praise and worship God:</b> Lift our voices together in
                  songs of adoration and thanksgiving.
                </li>
                <li>
                  <b>Share and pray:</b> Open our hearts and share testimonies,
                  prayer requests, and words of encouragement.
                </li>
                <li>
                  <b>Build community:</b> Strengthen our bonds of fellowship and
                  experience the love and support of our church family.
                </li>
              </ul>

              <p>
                Our cottage prayer meetings provide a relaxed and comfortable
                environment where you can:
              </p>
              <div class="highlight">
                <ul>
                  <li>
                    Experience a deeper sense of connection with God and others.
                  </li>
                  <li>
                    Feel comfortable sharing your joys and concerns in a
                    supportive setting.
                  </li>
                  <li>
                    Receive prayer and encouragement from fellow believers.
                  </li>
                  <li>Enjoy fellowship and build meaningful relationships.</li>
                </ul>
              </div>

              <h4>Join Us!</h4>
              <p>
                Our Cottage Prayer Ministry usually meets on the 4th Friday, once a
                month, at 07:00 PM. The location varies each month as we gather
                in the homes of different church members. For the location of our
                next meeting and to learn more, please contact the
                Presbyter-in-charge.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
