import React, { useEffect } from "react";

import "./about.scss";
import { useLocation } from "react-router-dom";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function About() {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      setTimeout(
        () =>
          document.querySelector(hash)?.scrollIntoView({ behavior: "smooth" }),
        300
      );
    }
  }, [hash]);

  useDocumentTitle("About Church");

  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/hero-bg-3.jpg)" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">About our Church</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="about-us">
        <div className="container">
          <div className="row gy-4">
            <div className="col-xs-12">
              <p>
                Welcome to Love of Christ CSI Church, a cherished spiritual
                haven situated in the Dallas-Fort Worth Metroplex area. Rooted
                in the rich traditions of the Church of South India (CSI), our
                church holds a special place for all Christians from South
                India seeking a familiar spiritual home in a foreign land.
              </p>

              <p>
                Our church proudly carries the heritage of the Church of
                South India, a union of several Protestant denominations,
                including the Methodist Church, Church of England,
                Congregational Church, and the Presbyterian Church. Our worship
                services incorporate elements of our South Indian Christian
                traditions matched with the contemporary worship, creating a
                unique and resonant experience for our community.
              </p>

              <p>
                As a community of Christians from South India, we celebrate the
                diversity and vibrancy of our cultural heritage. From special
                events featuring traditional South Indian hymns to cultural
                festivals that unite us in joy, we strive to foster an
                atmosphere that feels like home, even in a distant land.
              </p>

              <p>
                Love of Christ CSI Church recognizes the importance of
                language in worship. Our services are conducted in English while
                the worship songs and hymnals are sung in English, Tamil,
                Malayalam, Telugu and Kannada allowing our members to engage in
                worship, prayer, and the study of God's Word in a language that
                connects deeply with their hearts.
              </p>

              <p>
                We are committed to providing spiritual nourishment that
                resonates with our South Indian community. Whether it's the
                familiar cadence of hymns or the integration of traditional
                rituals, our church endeavors to create a spiritual space
                where South Indian Christians can feel a sense of belonging and
                connection.
              </p>

              <p>
                Also, living away from one's homeland can present unique
                challenges. At Love of Christ CSI Church, we understand the
                importance of community support. Our church provides a
                supportive network for christians and non-christians alike from
                South India, creating a space where individuals can share their
                journey, find encouragement, and build lasting friendships.
              </p>

              <p>
                Love of Christ CSI Church extends its outreach to connect
                with the wider South Indian community in DFW. Through cultural
                events, collaborative initiatives, and shared celebrations, we
                aim to strengthen the bonds within the broader South Indian
                diaspora, fostering a sense of unity and camaraderie.
              </p>

              <p>
                We extend a warm invitation to all christians from South India
                to plan a visit to Love of Christ CSI Church. Whether you
                are new to the area or seeking a church home that honors your
                cultural and spiritual roots, we look forward to welcoming you
                with open arms.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5" id="mission">
        <div className="container">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Mission</h2>
          </header>
          <div className="row text-center gy-4">
            <div className="col-xs-12">
              <p>
                Our mission is to bring together the disciples of Christ, to
                hear the Word of God, and to worship the Lord in accordance with
                the CSI guidelines and doctrines. We work in harmony with the
                Anglican Communion to grow in Love of Christ that we all may be
                one, and to inspire people to live Godly lives. We come together
                in fellowship through worship and prayer, and to build a strong
                Christian foundation for CSI believers and their children by
                teaching the Anglican doctrines.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5" id="vision">
        <div className="container">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Vision</h2>
          </header>
          <div className="row text-center gy-4">
            <div className="col-xs-12">
              <p>
                Our vision is to be witnesses to the communities around us and
                to inspire people to become disciples of Jesus Christ through
                words and deeds by sharing the good news of Jesus Christ and by
                serving the community with love. Our vision is to build strong
                Christian faith in our community and to guide the future
                generations with traditional CSI values and beliefs through a
                CSI Church. We aspire to expand our reach by impacting people’s
                lives in our community, and to become an inclusive and
                compassionate CSI Church with the Love of Christ CSI Church
                as its foundation.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
