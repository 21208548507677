import moment from "moment";
import { useEffect, useState } from "react";

export default function LiveStreaming({
  video = {},
  isUpcoming = false,
  isLoading = false,
}) {
  const [duration, setDuration] = useState(null);

  const updateTimer = (timestamp) => {
    setTimeout(() => {
      if (timestamp) {
        const total = Date.parse(timestamp) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        setDuration({
          total,
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);
  };

  useEffect(() => {
    if (isUpcoming) {
      updateTimer(video.scheduledStartTime);
    }
  }, [duration, isUpcoming, video]);

  return (
    <>
      {video && (
        <>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "VideoObject",
              contentURL: video.url,
              description: video.description,
              embedUrl: `https://www.youtube.com/embed/${video.id}`,
              regionsAllowed: "US",
              name: video.title,
              thumbnailUrl: video.thumbnail,
              uploadDate: video.scheduledStartTime,
              publication: [
                {
                  "@type": "BroadcastEvent",
                  isLiveBroadcast: true,
                  startDate: video.scheduledStartTime,
                }
              ],
              creditText: "Love of Christ CSI",
              creator: {
                "@type": "Organization",
                name: "Love of Christ CSI Church",
                url: "https://loveofchristcsichurch.org",
              },
            })}
          </script>
          <section className="pb-5">
            <div className="live-banner container-fluid p-0">
              <div className="live-container d-flex align-items-center">
                <a
                  className="mh-100 d-inline-block live-streaming-img"
                  target="_blank"
                  rel="noreferrer"
                  href={video.url}
                >
                  <img className="img-fluid" src={video.thumbnail} alt="..." />
                </a>
                <div className="mh-100 text-light container">
                  {!isUpcoming && (
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6">
                        Happening Live
                        <h4 className="mb-0">
                          <a
                            className="reset-anchor"
                            target="_blank"
                            rel="noreferrer"
                            href={video.url}
                          >
                            {video.title}
                          </a>
                        </h4>
                        <p className="text-serif text-small d-block">
                          Watch Now
                        </p>
                      </div>
                    </div>
                  )}
                  {isUpcoming && (
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6">
                        Next Live Event
                        <h4 className="mb-0">
                          <a
                            className="reset-anchor"
                            target="_blank"
                            rel="noreferrer"
                            href={video.url}
                          >
                            {video.title}
                          </a>
                        </h4>
                        <p className="text-serif text-small d-block d-md-none">
                          {moment(video.scheduledStartTime).format("LL h:mm a")}
                        </p>
                      </div>
                      <div className="col-md-6 text-center d-none d-md-block">
                        {duration && (
                          <>
                            <p className="text-serif mb-0">Live in</p>
                            <div
                              className="p-2 counter event-counter d-flex align-items-center justify-content-center text-center"
                              data-counter=".event-counter"
                              data-date="Sat Dec 26 2021 11:12:29 GMT"
                            >
                              <div className="day counter-item text-center mx-1">
                                <p className="h2 num mb-0">{duration.days}</p>
                                <span className="word text-serif">Days</span>
                              </div>
                              <div className="hour counter-item text-center mx-1">
                                <p className="h2 num mb-0">{duration.hours}</p>
                                <span className="word text-serif">Hours</span>
                              </div>
                              <div className="min counter-item text-center mx-1">
                                <p className="h2 num mb-0">
                                  {duration.minutes}
                                </p>
                                <span className="word text-serif">Minutes</span>
                              </div>
                              <div className="sec counter-item text-center mx-1">
                                <p className="h2 num mb-0">
                                  {duration.seconds}
                                </p>
                                <span className="word text-serif">Seconds</span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
