import { map } from "lodash";
import moment from "moment";
import Loader from "../../../components/loader";

export default function Events({ events = [], isLoading = false }) {
  return (
    <>
      <section className="py-5">
        <div className="container" id="upcoming_events_main">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">United in Spirit</p>
            <h2 className="text-uppercase">Next Events</h2>
          </header>
          {isLoading &&
            map([1, 2, 3], (i) => (
              <div
                key={`loader-${i}`}
                className="p-4 p-lg-5 bg-light text-center mb-4"
              >
                <div className="loader-event">
                  <Loader></Loader>
                </div>
              </div>
            ))}
          {!isLoading &&
            map(events, (event) => {
              const startDate = moment(event.start);
              const endDate = moment(event.end);
              return (
                <div
                  key={event.id}
                  className="p-4 p-lg-5 bg-light text-center mb-4"
                >
                  <div className="row gy-4 align-items-center">
                    <div className="col-lg-3">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <p className="text-gray text-xl h1 mb-0">
                          {startDate.format("DD")}
                        </p>
                        <div className="ms-2">
                          <h5 className="mb-0">{startDate.format("MMMM")}</h5>
                          <p className="text-small mb-0 text-muted">
                            {startDate.format("dddd")}
                          </p>
                        </div>
                      </div>
                      {startDate.format("L") !== endDate.format("L") && (
                        <div className="d-flex align-items-center justify-content-center justify-content-lg-start text-md text-secondary">
                          to&nbsp;
                          <span className="fw-bold">
                            {endDate.format("DD MMMM")}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 text-center">
                      <h4 className="text-primary mb-1">
                        <a className="reset-anchor" href={event.url}>
                          {event.title}
                        </a>
                      </h4>
                      <p className="text-serif mb-0 text-muted">
                        {event.location}
                      </p>
                      <p className="text-serif mb-0 text-muted">
                        {startDate.format("L") !== endDate.format("L") &&
                          startDate.format("L ")}
                        {startDate.format("LT")} to{" "}
                        {startDate.format("L") !== endDate.format("L") &&
                          endDate.format("L ")}
                        {endDate.format("LT")}
                      </p>
                    </div>
                    <div className="col-lg-3 text-lg-end">
                      <a
                        className="btn btn-outline-primary px-4"
                        href={event.url}
                      >
                        Find out more
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
}
