import { map } from "lodash";
import moment from "moment";
import Loader from "../../../components/loader";

export default function Sermons({
  videos = [],
  isLoading = false,
  onClick = () => {},
}) {
  return (
    <div className="row gy-5" id="youtube_list">
      {isLoading &&
        map([1, 2, 3], (i) => (
          <div key={`loader-${i}`} className="col-lg-4">
            <div className="loader-video">
              <Loader></Loader>
            </div>
          </div>
        ))}
      {!isLoading &&
        map(videos, (video) => (
          <div key={video.id} className="col-lg-4">
            <button
              className="btn btn-link reset-anchor d-block mb-3"
              onClick={() => onClick(video)}
            >
              <div className="overlay-content">
                <img
                  className="img-fluid w-100"
                  src={video.thumbnail}
                  alt="..."
                />
              </div>
              <h4 className="mb-0">{video.title}</h4>
            </button>
            <p className="text-serif text-primary text-small">
              {moment(video.published).format("LL")}
            </p>
            {/* <p className="text-muted">{video.description}</p> */}
          </div>
        ))}
    </div>
  );
}
