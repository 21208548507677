import React from "react";
import ReactGA from "react-ga4";
import "./contacts.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function Contacts() {
  useDocumentTitle("Contacts");
  ReactGA.send({ hitType: "pageview", page: "/contacts" });
  ReactGA.event("conversion", {
    send_to: "AW-16712956940/yAwYCIrrl9gZEIz4raE-",
  });

  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/contact-bg-4.jpg)" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Contacts</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5" id="visit-us">
        <div className="container py-5">
          <header className="mb-4 mb-5 text-center">
            <p className="text-serif mb-0 text-primary">Where to find us</p>
            <h2 className="text-uppercase">Visit us</h2>
            <div className="row">
              <div className="col-lg-7 mx-auto">
                <p className="text-muted">
                  We welcome you to join us for worship, fellowship, and
                  community at Love of Christ CSI Church. Visit us in person to
                  experience the warmth of our congregation and the
                  transformative power of God's love.
                </p>
              </div>
            </div>
          </header>
          <div className="row align-items-stretch gx-0">
            <div className="col-lg-6">
              <div
                className="contact-img bg-center bg-cover"
                style={{ background: "url(img/contact-bg.png)" }}
              ></div>
            </div>
            <div className="col-lg-6">
              <div className="bg-light p-5 h-100">
                <h4>Love of Christ CSI Church</h4>
                {/* <p className="text-muted">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p> */}
                <ul className="list-unstyled mb-0 text-muted">
                  <li className="mb-2">
                    <a className="reset-anchor" href="tel:+12146999525">
                      <i className="text-primary me-3 fas fa-phone"></i>
                      <span>+1 214-699-9525</span>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="reset-anchor"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/maps/dir/?api=1&destination=12717%20Marsh%20Ln%2C%20Farmers%20Branch%2C%20TX%2075234&dir_action=navigate"
                    >
                      <i className="text-primary me-3 fas fa-globe-americas"></i>
                      <span>12717 Marsh Ln, Farmers Branch, TX 75234</span>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="reset-anchor"
                      href="mailto:welcome@loccsi.org"
                    >
                      <i className="text-primary me-3 far fa-envelope"></i>
                      <span>welcome@loccsi.org</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5" id="contact">
        <div className="container">
          <header className="mb-4 mb-5 text-center">
            <p className="text-serif mb-0 text-primary">How to reach us</p>
            <h2 className="text-uppercase">Contacts</h2>
          </header>
          <div className="row align-items-stretch gx-0">

            <div className="col-12 col-lg-6 mb-4">
              <div className="row">
                <div className="col-3">
                  <img src="/img/committee.png" className="img-fluid img-thumbnail" alt="Committee"></img>
                </div>
                <div className="col-9">
                  <h4>Church Committee</h4>
                  <a href="mailto:committee@loccsi.org">committee@loccsi.org</a>
                </div>
              </div>
            </div>


            <div className="col-12 col-lg-6">
             
            </div>


            <div className="col-12 col-lg-6 mb-4">
              <div className="row">
                <div className="col-3">
                  <img src="/img/sherwin_contact.jpg" className="img-fluid img-thumbnail" alt="Member"></img>
                </div>
                <div className="col-9">
                  <h4>Pastor / President</h4>
                  <p>Rev. Sherwin Doss</p>
                  <a href="mailto:pastor@loccsi.org">pastor@loccsi.org</a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="row">
                <div className="col-3">
                  <img src="/img/stanlin_contacts.jpg" className="img-fluid img-thumbnail" alt="Member"></img>
                </div>
                <div className="col-9">
                  <h4>Vice President</h4>
                  <p>Stanlin Jebaraj</p>
                  <a href="mailto:vicepresident@loccsi.org">vicepresident@loccsi.org</a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="row">
                <div className="col-3">
                  <img src="/img/anil_contacts.jpg" className="img-fluid img-thumbnail" alt="Member"></img>
                </div>
                <div className="col-9">
                  <h4>Secretary</h4>
                  <p>Iype Chandy</p>
                  <a href="mailto:secretary@loccsi.org">secretary@loccsi.org</a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="row">
                <div className="col-3">
                  <img src="/img/daya_contacts.jpg" className="img-fluid img-thumbnail" alt="Member"></img>
                </div>
                <div className="col-9">
                  <h4>Treasurer</h4>
                  <p>Daya Emmanuel</p>
                  <a href="mailto:treasurer@loccsi.org">treasurer@loccsi.org</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
