import { map } from "lodash";
import moment from "moment";
import Loader from "../../../components/loader";

export default function Sermons({ videos = [], isLoading = false }) {
  return (
        <div className="row gy-5" id="youtube_list">
          {isLoading &&
            map([1, 2, 3], (i) => (
              <div key={`loader-${i}`} className="col-lg-4">
                <div className="loader-video">
                  <Loader></Loader>
                </div>
              </div>
            ))}
          {!isLoading &&
            map(videos, (video) => (
              <div key={video.id} className="col-lg-4">
                <a
                  className="primary-overlay d-block mb-3"
                  target="_blank"
                  rel="noreferrer"
                  href={video.url}
                >
                  <div className="overlay-content">
                    <img
                      className="img-fluid w-100"
                      src={video.thumbnail}
                      alt="..."
                    />
                  </div>
                </a>
                <h4 className="mb-0">
                  <a
                    className="reset-anchor"
                    target="_blank"
                    rel="noreferrer"
                    href={video.url}
                  >
                    {video.title}
                  </a>
                </h4>
                <p className="text-serif text-primary text-small">
                  {moment(video.published).format("LL")}
                </p>
                {/* <p className="text-muted">{video.description}</p> */}
              </div>
            ))}
        </div>
  );
}
